export const otherInterests = [
  {
    text: "Woodworking: I have been a woodworker my whole adult life. I really  like making stuff and am very handy. I have made tables, signs, cuttingboards, pens and much more.",
  },
  {
    text: "Corvettes: Since I was a little boy, I have always loved Corvettes. I have actually owned 3 so far and am just starting to look for my next one",
  },
  {
    text: "I am a huge fan NFL and College Football",
  },
  {
    text: "I also enjoy getting out into the colorado backcountry, mostly hiking.",
  },
];
